import * as React from 'react';

const NotFoundPage = (props) => {
  return (
    <main className="h-screen w-screen">
      <title>404 Not Found</title>
      <div className="wrapper">
        <div className="row">
          <div className="col-12 mt-24">
            <h1 className="type-scaling-20040 mb-12">Oopsies</h1>
          </div>
          <div className="col-10 md:col-6">
            <p className="type-2240">
              The page you're looking for does not exist... yet! <br /> One can
              only dream ;)
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
